<template>
  <el-container>
    <el-header>
      <Header></Header>
    </el-header>
    <el-row type="flex" justify="center" class="bg">
      <el-col :md="10" :sm="20">
        <el-card class="box-card">
          <template #header>
            <div class="card-header">
              <h2>Benvenuto Sul Portale di ESD Italia</h2>
              <h4>Effettua l'accesso prima di cominciare</h4>
            </div>
          </template>
          <el-form label-width="auto" :model="loginForm" status-icon :rules="rules" ref="loginForm">
            <el-form-item :label="$t('login.formLabels.username')" prop="email">
              <el-input v-model="loginForm.email"></el-input>
            </el-form-item>
            <el-form-item :label="$t('login.formLabels.password')" prop="password">
              <el-input type="password" v-model="loginForm.password"></el-input>
            </el-form-item>
            <div style="display: flex; justify-content: flex-end;">
              <el-button block type="primary" @click="submit">Login</el-button>
            </div>
          </el-form>
        </el-card>
        <div style="padding: 5px">
          <el-link @click="goTo('activation')"> {{$t("login.hint")}}  </el-link>
        </div>

      </el-col>
    </el-row>
    <el-footer>
      <Footer></Footer>
    </el-footer>
  </el-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Footer from "../components/Footer";
import Header from "../components/Header";

export default {
  components: {Header, Footer},
  data() {
    const validateEmail = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t("login.callback.username")));
      } else {
        if (this.loginForm.email !== '') {
          this.$refs.loginForm.validateField("email");
        }
        callback();
      }
    };
    const validatePassword = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Inserisci la password'));
      } else {
        if (this.loginForm.password !== '') {
          this.$refs.loginForm.validateField("password");
        }
        callback();
      }
    };
    return {
      loginForm: {
        password: "",
        email: "",
      },
      rules: {
        password: [
          {validator: validatePassword, trigger: 'blur'}
        ],
        email: [
          {validator: validateEmail, trigger: 'blur'}
        ]
      }
    };
  },
  computed: {
    ...mapGetters(["isLogged", "isFirstAccess"])
  },
  methods: {
    ...mapActions(["login", "setUserActivationData"]),
    submit() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.setUserActivationData({...this.loginForm})
          this.login({...this.loginForm}).then(
              () => {

                if (this.isLogged) {
                  this.$router.push("dashboard");
                }
              }
          )
        } else {
          return false;
        }
      });
    },
    goTo(name) {
      this.$router.push({name})
    },
  }
}
</script>

<style scoped>
.bg {
  height: 100vh;
  background: #ededed;
  width: 100%;
  align-items: center;
}


</style>
